import react from "react";
import EventJSON from "../../../assets/json/InterestingEvents.json"
import Event from "../../../components/Event/Event";
import "./InterestingEvents.css"

/**
 * This is the InterestingEvents page. It contains a list of events that the user can filter by event type by toggling
 * the buttons at the top of the page. The events are generated from the InterestingEvents.json file, and mapped to
 * Event components.
 */
export default class InterestingEvents extends react.Component {

	constructor(props) {
		super(props);
		this.state = {
			// Initialize all buttons to false
			buttons: Object.values(EventJSON.TagList).reduce((acc, obj) => ({ ...acc, [obj]: false }), {}),
			videos: [],
			isLoading: false,
			date: "",
			time: "",
		};
	}

	componentDidMount() {
		this.fetchInterestingVideos();
	}

	fetchInterestingVideos = () => {
		fetch('/api/interesting-videos')
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error('Network response was not ok.');
				}
			})
			.then(data => {
				this.setState({ videos: data, isLoading: false });
				console.log(data)
				console.log(data[0].HiveName)
				console.log(data[0].TimeStamp)

		})
		.catch(error => this.setState({ error, isLoading: false }));
	}

	setButton(buttonName) {
		this.setState(prevState => ({
			buttons: {
				...prevState.buttons,
				[buttonName]: !prevState.buttons[buttonName]
			}
		}));
	}

	meetsFilters(EventsTags) {
		for (let tag in this.state.buttons) {
			// Filter all events that do not have all the tags selected by the user
			if (this.state.buttons[tag] && !EventsTags.includes(tag)) {
				//console.log("excluding tag: " + tag);
				return false;
			}
		}
		return true;
	}



	render() {
		return (
				<div className="interesting-events-tab">
					<div className="banner">
						{/* Add Banner at the top*/}
						<h1>Interesting Events</h1>
					</div>
					<div className="buttons-container">
						{/*Add container for button section */}
						{Object.values(EventJSON.TagList).map(
								(tag) => {
									// if the button is selected, make it blue, otherwise make it grey
									const buttonColor = this.state.buttons[tag] ? "#0a84ff" : "#8e8e93";
									return (
										<div className="button-org" >
											<div className="event-button" onClick={() => this.setButton(tag)}
												 style={{backgroundColor: buttonColor}}> {tag} </div>
										</div>
									)
								}
						)}
					</div>
					<div className="grid-container"> {/* this will be a container for all the Events. */}
						{/* This will generate the events from the data of the json file.*/}
						{Object.values(this.state.videos).map(
								(event) => {
									if (this.meetsFilters(event.Tags)) {
										return (
												<div className="grid-item" >

													<Event
															description={event.Description}
															hivename={event.HiveName}
															timestamp={event.TimeStamp}
															tags={event.Tags}
															eventTitle={event.Name}
													/>
												</div>
										)
									}
									 else{
										// console.log("excluded event: " + event.HiveName + " because it did not meet filters");
										 return null;
									}
								}
						)
						}
					</div>
				</div>
		)
	}
}