import react, { useEffect, useState } from "react";
import "./LabelTagButton.css";
//Needs https Change
export default function LabelTagButton(props) {
    const [isSelected, setIsSelected] = useState(true);
    const [buttonColor, setButtonColor] = useState("#8e8e93");
    const [tagCount, setTagCount] = useState(0);
    useEffect(() => {
        const passedParams = new URLSearchParams({
            filePath: props.filePath,
            tag: props.label,
        }).toString();

        const fetchTagCount = async () => {
            try {
                const response = await fetch(
                    `/api/tags/retrieveTags?${passedParams}`,
                );
                const data = await response.json();
                if (data[0] !== undefined) {
                    console.log(data[0].Count);
                    setTagCount(data[0].Count);
                }
            } catch (error) {
                console.error("Error: ", error);
                setTagCount(0);
            }
        };
        fetchTagCount();
    }, [props.filePath, props.label]);

    const fetchingData = async () => {
        //console.log("Getting Data...")

        try {
            const response = await fetch(
                "/api/tags/updateCount",
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        videoUrl: props.filePath,
                        tag: props.label,
                        isSelected: isSelected,
                        hiveName: props.hiveName,
                        UTCDateVar: props.UTCDate,
                    }),
                },
            );
            const data = await response.json();

            //console.log("Success: " + data.Count)
            if (data.Count === undefined) setTagCount(0);
            else setTagCount(data.Count);
        } catch (error) {
            console.error("Error: " + error);
        }
        setButtonColor(isSelected ? "#0a84ff" : "#8e8e93");
    };

    const toggleSelected = () => {
        fetchingData();
        setIsSelected((prevIsSelected) => !prevIsSelected);
    };

    return (
        <div className="button-org">
            <div
                className="event-button"
                onClick={() => toggleSelected()}
                style={{ backgroundColor: buttonColor }}
            >
                {" "}
                <b>{tagCount} </b> {props.label}{" "}
            </div>
        </div>
    );
}
